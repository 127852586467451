import clsx from 'clsx';
import {
    FieldsetHTMLAttributes,
    ForwardedRef,
    forwardRef,
    InputHTMLAttributes,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    SelectHTMLAttributes,
    TextareaHTMLAttributes,
} from 'react';
import { Form } from '@remix-run/react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    preFix?: ReactNode;
    postFix?: ReactNode;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {}

interface InputGroupProps {
    label?: string;
    errors?: string[];
}

interface FieldSetProps extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
    label?: string;
}

function InputGroup(props: PropsWithChildren<InputGroupProps>): ReactElement {
    const { label, errors, children } = props;

    if (!label) return <div>{children}</div>;

    return (
        <div>
            <label className="flex flex-wrap justify-between items-center">
                <span className="flex-none text-sm font-medium text-gray-700">
                    {label}
                </span>
                {children}
            </label>
            <div className="text-red-600">{errors?.join('; ')}</div>
        </div>
    );
}

function Input(
    props: InputProps,
    ref: ForwardedRef<HTMLInputElement>
): ReactElement {
    const { className, preFix, postFix, type = 'text', ...inputProps } = props;

    return (
        <div
            className={clsx(
                'mt-1 relative rounded-md shadow-sm',
                type !== 'checkbox' && 'w-full flex-none',
                type === 'checkbox' && 'text-right inline-block'
            )}
        >
            {preFix && type !== 'checkbox' ? (
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-500 sm:text-sm mr-2 px-2 border-gray-300 border-l">
                    {preFix}
                </div>
            ) : null}

            <input
                type={type}
                ref={ref}
                {...inputProps}
                className={clsx(
                    'appearance-none border p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md',
                    preFix && 'pl-7',
                    postFix && 'pr-7',
                    type !== 'checkbox' && 'block w-full',
                    inputProps.disabled && 'bg-gray-100',
                    className
                )}
            />
            {postFix && type !== 'checkbox' ? (
                <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none text-gray-500 sm:text-sm ml-2 px-2 border-gray-300 border-l">
                    {postFix}
                </div>
            ) : null}
        </div>
    );
}

function Textarea(
    props: TextareaHTMLAttributes<HTMLTextAreaElement>,
    ref: ForwardedRef<HTMLTextAreaElement>
): ReactElement {
    return (
        <div className={clsx('mt-1 relative rounded-md shadow-sm w-full ')}>
            <textarea
                className={clsx(
                    'block w-full appearance-none border p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                )}
                ref={ref}
                {...props}
            />
        </div>
    );
}

function Select(
    props: SelectProps,
    ref: ForwardedRef<HTMLSelectElement>
): ReactElement {
    const { className, ...selectProps } = props;

    return (
        <div className="mt-1 relative rounded-md shadow-sm w-full">
            <select
                ref={ref}
                {...selectProps}
                className={clsx(
                    'border p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
                    className
                )}
            />
        </div>
    );
}

function FieldSet(props: FieldSetProps): ReactElement {
    const { children, className, label, ...rest } = props;
    return (
        <fieldset className={clsx('border p-4', className)} {...rest}>
            {label ? <legend>{label}</legend> : null}
            <div className="space-y-4">{children}</div>
        </fieldset>
    );
}

export default Object.assign({}, Form, {
    InputGroup,
    FieldSet,
    Input: forwardRef(Input),
    Textarea: forwardRef(Textarea),
    Select: forwardRef(Select),
});
